import NewAboutUs from 'src/components/Institucional/NewAboutUs'
import Seo from 'src/components/seo/Seo'

function AboutDecathlon() {
  return (
    <>
      <Seo />
      <NewAboutUs />
    </>
  )
}

export default AboutDecathlon
